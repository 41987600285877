import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
} from 'common';

const Container = styled.div`
  position: absolute;
  background-color: ${Colors.LighterGrey};
  color: ${Colors.White};
  height: 13px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: ${FontSizes.Smallest};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px 0 0;
`;

const Min = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${Colors.DarkGrey};
  width: ${props => props.width}%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px 0 0;

  ${props => props.width || `min-width: 5%;`}
`;

const Commited = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${Colors.PrimaryOne};
  width: ${props => props.width}%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const EventCommitProgressBar = props => {

  const propsMin = props.min || 0;
  const propsMax = props.max || 1;
  const propsCommited = props.commited || 0;

  const min = (propsMin / propsMax) * 100;
  const commited = (propsCommited / propsMax) * 100;

  return (

    <Container>
      
      <Min
        width={min}>
      
          Min: {propsMin}
      </Min>

      <Commited
        width={commited}>

          &nbsp;&nbsp;{`Commited: ${propsCommited}`}
      </Commited>

      {`Max: ${propsMax}`}

    </Container>
  );
};