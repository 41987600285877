let envConfig;

if (window.location.hostname.includes('weticket-prod.netlify.app') || window.location.hostname.includes('weticket.co.za')) {

  envConfig = {
    name: 'Production',
    uiHost: '/',
    apiHost: 'https://weticket-api-prod-937541674863.us-central1.run.app/',
    assetBase: '/assets',
  };
}
else if (window.location.hostname.includes('weticket-dev.netlify.app')) {

  envConfig = {
    name: 'QA',
    uiHost: '/',
    apiHost: 'https://weticket-api-dev-791836476647.us-central1.run.app/',
    assetBase: '/assets',
  };
}
else {

  envConfig = {
    name: 'Development',
    uiHost: '/',
    apiHost: 'http://localhost:8080/',
    assetBase: '/assets',
  };
}

export const Environment = envConfig;