import
  React, {
  Component,
} from 'react';

import
  CommonDatePicker
from 'react-datepicker';

import
  styled
from 'styled-components';

import {
  TextInput,
} from './TextInput';

import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div`
  width: ${props => props.width || '100%'};

  .react-datepicker-wrapper {
    width: ${props => props.width || '100%'};
  }
`;

// NOTE: this is a hack
// We had to wrap the TextInput in a class component as there is an issue with the date module
class CustomInput extends Component {

  render () {

    return (

      <TextInput
        {...this.props}
        value={this.props.value}
        readonly={'readonly'}
      />
    );
  };
}

export const DatePicker = props => {

  const [dateString, setDate] = React.useState('');

  const onChange = d => {

    setDate(d.toString());
    typeof props.onChange === 'function' && props.onChange(d);
  };

  const date = dateString || props.value
    ? new Date(dateString || props.value)
    : new Date();

  return (
    <Container>

      <CommonDatePicker
        selected={date}
        onChange={onChange}
        dropdownMode={'scroll'}
        showYearDropdown
        customInput={
          <CustomInput
            {...props}
            value={props.value}
            readonly={'readonly'}
          />
        }
      />

    </Container>
  );
};