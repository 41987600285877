import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontSizes,
  FontFamilies,
  MediaQueries,
  Image,
  Logger,
  isValidProviderResult
} from 'common';

import {
  Arrow,
  Button,
  Card,
  NumericSelect,
  Stepper,
  Text,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  EventMobileTicketSummary
} from 'ui/event/components';

import {
  ActionContainer,
  Container,
  DetailContainer,
  DetailContent,
  DetailContentText,
  EventName,
  FullWidthContainer,
  MainContentContainer,
  MobileContentSpace,
  SideContentContainer,
  SideContent,
  StepperContainer,
} from 'ui/event/styled';

import {
  EventProvider
} from 'providers';

const ActionContainerPopupOverlay = styled.div`
  background-color: ${Colors.Black}60;

  position: fixed;
  bottom: 65px;
  left: 0;
  top: 0;
  right: 0;

  ${props => !props.show && 'display: none;'}

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const ActionContainerPopup = styled.div`
  background-color: ${Colors.GreyBrownSecond};
  width: 100%;

  position: fixed;
  bottom: ${props => props.show ? '65px' : '-100%'};
  left: 0;

  transition: bottom 0.5s ease 0s;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px 0 0;
`;

const MerchContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
  }
`;

const VariantContainer = styled.div`
  flex: 1;
  padding: 15px 0 0 0;

  @media only screen and ${MediaQueries.Md} {
    padding: 0 0 0 20px;
  }
`;

const EventMerchSelectUI = props => {

  const [state, setState] = React.useState({
    ticketPrices: [],
    products: [],
    event: {}
  });

  const [totals, setTotals] = React.useState(DataStore.get('selectedTickets') || {
    selectedTickets: {},
    selectedMerch: {},
    price: 0,
    tickets: 0,
  });

  const [showSummary, setShowSummary] = React.useState(false);

  const onShowSummaryClick = () => {

    setShowSummary(true);
  };

  const onHideSummaryClick = () => {

    setShowSummary(false);
  };

  const onMerchCounterChange = (count, product_id, option_id) => {

    if (!product_id || !option_id) {
      return;
    }

    const product = state.products.find(p => p.product_id === product_id);

    const variant = product.variants.find(v => v.option_id === option_id);

    const selectedMerch = totals.selectedMerch;

    selectedMerch[variant.product_variant_id] = {
      ...variant,
      product_name: product.product_name,
      description: product.description,
      product_id: product.id,
      count: count,
      totalPrice: count * variant.price,
    };

    let totalPrice = 0;
    let totalTickets = 0;

    for (const item of Object.values(totals.selectedTickets)) {

      totalPrice += item.totalPrice;
      totalTickets += item.count;
    }

    for (const item of Object.values(selectedMerch)) {

      totalPrice += item.totalPrice;
      totalTickets += item.count;
    }

    setTotals(prevState => ({
      ...prevState,
      selectedMerch: selectedMerch,
      price: totalPrice,
      tickets: totalTickets,
    }));

    setTimeout(() => {
      DataStore.set('selectedTickets', {
        selectedTickets: totals.selectedTickets,
        selectedMerch: selectedMerch,
        price: totalPrice,
        tickets: totalTickets
      });
    }, 0);
  };

  const load = () => {

    if (!props.common?.location?.search) {

      Logger.error('MerchSelect', 'load', 'No search provided');
      props.common.showToast('Oh no, we weren\'t able to load the event details, please try again.');
      return;
    }

    const searchParams = new URLSearchParams(props.common.location.search);

    if (!searchParams.get('e_id')) {

      Logger.error('MerchSelect', 'load', 'No e_id provided');
      props.common.showToast('Oh no, we weren\'t able to load the event details, please try again.');
      return;
    }

    EventProvider.getProductsByEventId(searchParams.get('e_id'))
      .then(ret => {
        
        if (!isValidProviderResult(ret) || !Array.isArray(ret.data)) {
          return;
        }

        DataStore.set('selectedEvent', { id: searchParams.get('e_id') });

        setState(prevState => ({
          ...prevState,
          products: ret.data
        }));
      })
      .catch(e => Logger.error('MerchSelect', 'getProductsByEventId', e))
      .finally(() => props.common.hideLoading());
  };

  React.useEffect(load, []); // eslint-disable-line

  let productComponents = [];

  for (const product of state.products || []) {

    let variantComponents = [];

    for (const variant of product.variants || []) {

      const isSoldOut = variant.available_stock <= 0;

      variantComponents.push(
        <DetailContainer
          key={`variant-${variant.option_id}`}
          borderColor={variantComponents.length === 0 ? 'transparent' : Colors.LightGreyBrown}
          padding={'6px 0'}>

          <DetailContent
            padding={'0'}>

            <Text
              display={'block'}
              fontFamily={FontFamilies.Regular}>
                {variant.option_value}

                { isSoldOut

                  ? <span> (Sold Out)</span>

                  : variant.available_stock <= 15

                      ? <span style={{ fontSize: FontSizes.Small, fontFamily: FontFamilies.Regular }}> (Only {variant.available_stock} left)</span>

                      : <></>
                }
            </Text>

            <Text
              display={'block'}
              fontFamily={FontFamilies.Regular}
              fontSize={FontSizes.Small}>
                <b>R{variant.price}</b>
            </Text>

          </DetailContent>

          <div onClick={() => isSoldOut && props.common.showToast('Item sold out')}>
            <NumericSelect
              defaultValue={totals.selectedMerch[variant.product_variant_id]?.count}
              disabled={isSoldOut}
              max={variant.available_stock}
              onCounterChange={(count) => onMerchCounterChange(count, product.product_id, variant.option_id)}
            />
          </div>

        </DetailContainer>
      );
    }

    productComponents.push(
      <DetailContainer
        key={`product-${product.product_id}`}
        borderColor={Colors.LightGreyBrown}>

        <DetailContent
          padding={'0'}>

          <Text
            display={'block'}
            fontFamily={FontFamilies.Bold}
            fontSize={'1.1rem'}>
              {product.product_name}
          </Text>

          <Text
            display={'block'}
            fontSize={FontSizes.Smaller}
            padding={'0 0 2px 0'}>
              {product.description}
          </Text>

          <MerchContainer>

            <div style={{padding: '10px 0 0 0'}}>
              <Image
                src={product.image_url}
                width={'200px'}
                height={'auto'}
                borderRadius={'8px'}
              />
            </div>

            <VariantContainer>{variantComponents}</VariantContainer>

          </MerchContainer>

        </DetailContent>

      </DetailContainer>
    );
  }

  return (

    <>

    <EventName>{state.event.name}</EventName>

    <Text display={'block'} padding={'0 0 5px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.location_display}</Text>

    <Text display={'block'} padding={'0 0 20px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.displayDate}</Text>

    <FullWidthContainer>

      <StepperContainer>

        <Stepper
          items={[{text: 'ORDER'}, {text: 'DETAILS'}, {text: 'PAYMENT'}]}
          selectedIndex={0}
        />

      </StepperContainer>

    </FullWidthContainer>

    <Container>

      <MainContentContainer>

        { productComponents.length > 0 &&

          <Card
            backgroundColor={Colors.GreyBrown}
            alignItems={'flex-start'}
            flexDirection={'column'}
            padding={'10px 0'}
            margin={'0 0 20px 0'}>

            <Text fontSize={FontSizes.Big} fontFamily={FontFamilies.Bold} padding={'0 20px 2px 20px'}>Select Merch</Text>

            <Text fontSize={FontSizes.Small} padding={'0 20px 10px 20px'}>*Note: Delivery not included, merch can be claimed at the gate.</Text>

            { productComponents }

          </Card>
        }

        <MobileContentSpace
          height={'80px'}
        />

        <ActionContainerPopupOverlay
          show={showSummary}
          onClick={onHideSummaryClick}
        />

        <ActionContainerPopup
          show={showSummary}>

          <EventMobileTicketSummary
            totals={totals}
            onArrowClick={onHideSummaryClick}
          />

        </ActionContainerPopup>

        <ActionContainer>

          <ArrowContainer
            onClick={onShowSummaryClick}>

            <Arrow
              active={true}
              size={'10px'}
              direction={'up'}
            />

          </ArrowContainer>
          
          <DetailContent
            padding={'0'}>

            <DetailContentText fontSize={FontSizes.Small}>Price:</DetailContentText>
            <DetailContentText fontSize={FontSizes.Big} bold>{`R${totals.price}`}</DetailContentText>

          </DetailContent>

          <Button onClick={() => {

            if (totals.tickets === 0) {
              props.common.showToast('Please add items before checkout');
              return;
            }

            props.common.navigate('/event/ticket/identity')
            }}>Checkout</Button>

        </ActionContainer>

      </MainContentContainer>

      <SideContentContainer>

        <SideContent
          marginTop={'0'}
          top={'60px'}>

          <EventMobileTicketSummary
            totals={totals}
            onCheckoutClick={() => {

              if (totals.tickets === 0) {
                props.common.showToast('Please add items before checkout');
                return;
              }
              
              props.common.navigate('/event/ticket/identity')
            }}
          />

        </SideContent>

      </SideContentContainer>

    </Container>

    </>
  );
};

export const EventMerchSelect = withCommon(EventMerchSelectUI);