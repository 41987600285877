import
  React
from 'react';

import
  styled
from 'styled-components';

import ReactGA from 'react-ga4';

import {
  Environment,
  MediaQueries
} from 'common';

import {
  Header,
  Loader,
  Text,
  Toast,
} from 'common/components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.backgroundColor};
`;

const LoaderContainer = styled(Content)`
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  padding: 0 0 5vh 0;
`;

const ContentContent = styled.div`
  position: relative;
  width: 100%;
  ${props => props.applyMaxWidth && 'max-width: 1600px'};

  @media only screen and ${MediaQueries.Md} {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const withCommon = (WrappedComponent, options = {}) => {

  const {
    applyMaxWidth = true,
    showHeader = true,
    showProfileMenu = false,
    transparentHeader = false,
    showLoadingOnLoad = true,
    contentBackgroundColor = '#292A2D',
  } = options;

  return props => {

    const [showLoader, setShowLoader] = React.useState({
      show: showLoadingOnLoad,
      message: ''
    });

    const [toast, setToast] = React.useState({
      show: false,
      message: ''
    });

    const navigate = (path) => {

      if (!path || !props.history) {
        return;
      }

      typeof props.history.push === 'function' && props.history.push(path);
    };

    const showLoading = (message) => {

      setShowLoader({
        show: true,
        message: message
      });
    };

    const hideLoading = () => {

      setShowLoader({
        show: false,
        message: ''
      });
    };

    const showToast = (message) => {

      setToast({
        show: true,
        message: message
      });
    };

    const hideToast = () => {

      setToast({
        show: false,
        message: ''
      });
    };

    const common = {
      pathParams: props.match?.params || {},
      history: props.history,
      location: props.location,
      navigate,
      showLoading,
      hideLoading,
      showToast,
      hideToast,
    };

    React.useEffect(() => {

      if (Environment.name === 'Production') {
        const page = props.location.pathname + props.location.search;
        ReactGA.send({ hitType: "pageview", page: props.location.pathname + props.location.search, title: page });
      }
    }, [props.location]);

    return (

      <Container>

        { showHeader &&

          <Header
            onLogoClick={() => navigate('/home')}
            showProfileMenu={showProfileMenu}
            isBackgroundTransparent={transparentHeader}
          />
        }

        <LoaderContainer show={showLoader.show}>

          <Loader/>

          { showLoader.message && 
          
            <Text zIndex={1} padding={'20px 0 0 0'}>{showLoader.message}</Text>
          }

        </LoaderContainer>
    
        <Content
          id={'main-content'}
          show={!showLoader.show}
          backgroundColor={contentBackgroundColor}>

          <ContentContent
            applyMaxWidth={applyMaxWidth}>

            <WrappedComponent
              common={common}
            />

          </ContentContent>

        </Content>

        <Toast
          show={toast.show}
          message={toast.message}
          onClose={hideToast}
        />

      </Container>
    );
  };
};