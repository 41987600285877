import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  FontFamilies,
} from 'common';

import {
  Card,
} from './Card';

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  background: linear-gradient(45deg, ${Colors.PrimaryOne} 5%, ${Colors.Transparent} 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Text = styled.div`
  color: ${props => props.color || Colors.White};
  font-size: ${props => props.fontSize || FontSizes.Big};
  font-family: ${props => props.fontFamily || FontFamilies.Bold};
`;

export const CategoryCard = props => {

  return (

    <Card
      width={props.width || '200px'}
      height={props.height || '100px'}>

      { props.image &&

        <Image
          src={props.image}
        />
      }

      <TextContainer>

        <Text
          color={props.color}
          fontFamily={props.fontFamily}
          fontSize={props.fontSize}>

            {props.text}
        </Text>

      </TextContainer>

    </Card>
  );
};