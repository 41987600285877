import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class AuthProvider {

  static async requestCode(email) {

    try {

      let url = `${Environment.apiHost}auth/code/request`;

      let res = await axios.post(url, {
        email: email
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async resendCode(token) {

    try {

      let url = `${Environment.apiHost}auth/code/resend`;

      let res = await axios.post(url, {
        guid: token
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async submitCode(guid, code, receiveMarketing) {

    try {

      let url = `${Environment.apiHost}auth/code/submit`;

      let res = await axios.post(url, {
        guid: guid,
        code: code,
        receiveMarketing: receiveMarketing
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}