import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class LogProvider {

  static async error(
    component = '',
    method = '',
    ex
  ) {

    try {

      let message = '';
      let stack = '';

      if (ex?.stack) {
        stack = ex.stack;
      }

      if (ex?.message) {
        message = ex.message;
      }

      if (typeof ex === 'string') {
        message = ex;
      }

      let res = await axios.post(`${Environment.apiHost}logger/log`, {
        component,
        method,
        message,
        stackTrace: stack,
        level: 'error'
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}