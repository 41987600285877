import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceInfo,
  Images,
  Image,
  MediaQueries,
} from 'common';

import {
  Arrow,
} from './Arrow';

import {
  UserMenu,
} from './UserMenu';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 35px;
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: center;
  z-index: 4;

  @media only screen and ${MediaQueries.Md} {
    justify-content: space-between;
  }
`;

const Fade = styled.div`
  background-color: ${Colors.Black};
  box-shadow: 0px 5px 30px 30px ${Colors.Black};
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const LogoImage = styled.img`
  height: 45px;
  width: auto;
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  width: 45px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

const UserMenuContainer = styled.div`
  position: fixed;
  left: ${props => props.left || '100%'};
  bottom: 0;
  top: 0;
  
  ${DeviceInfo.IsMobile
    ? `width: 100%;`
    : `width: 300px;`}

  transition: left .4s ease-out;
  z-index: 5;
`;

const ArrowContainer = styled.div``;

export const Header = (props) => {

  const [showMenu, showMenuSetState] = React.useState(false);

  const onMenuToggle = () => {
    showMenuSetState(!showMenu);
  };

  const desktopLeft = window.innerWidth - 300;

  const menuLeft = showMenu
    ? (DeviceInfo.IsMobile ? '0' : `${desktopLeft}px`)
    : '100%';

  return (

    <Container>

      { !props.isBackgroundTransparent &&

        <Fade/>
      }

      { <LogoImage
          src={Images.Logo}
          onClick={() => props.onLogoClick && props.onLogoClick()}
        />
      }

      { props.showProfileMenu &&
        
        <React.Fragment>

          <ProfileContainer
            onClick={onMenuToggle}>

            <Image
              height={'100%'}
              src={Images.Icons.UserIcon}
            />

            <ArrowContainer>

              <Arrow
                direction={'down'}
                size={'8px'}
                active={true}
              />

            </ArrowContainer>

          </ProfileContainer>

          <UserMenuContainer
            left={menuLeft}>

            <UserMenu
              showClose={true}
              onCloseClick={onMenuToggle}
            />

          </UserMenuContainer>

        </React.Fragment>
      }

    </Container>
  );
};