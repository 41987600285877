import
  axios
from 'axios';

import {
  DataStore,
} from 'common';

const setHeaders = (headers = {}) => {

  const accessToken = DataStore.get('ACCESS_TOKEN');
  const traceId = DataStore.get('TRACE_ID') || 'NoTrace';

  if (accessToken) {
    headers['Authorization'] = accessToken;
  }

  if (traceId) {
    headers['wt-trace-id'] = traceId;
  }

  return headers;
};

const get = async (url, queryParams = {}, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.get(
    url,
    {
      headers: headers,
      params: queryParams
    }
  );
}

const post = async (url, data, headers = {}, options = {}) => {

  headers = setHeaders(headers);

  const requestOptions = {
    headers: headers,
    ...options,
  };

  return await axios.post(
    url,
    data,
    requestOptions,
  );
}

const put = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.put(
    url,
    data,
    {
      headers: headers,
    }
  );
}

const patch = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.patch(
    url,
    data,
    {
      headers: headers,
    }
  );
}

const deleteCall = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.delete(
    url,
    {
      headers: headers,
      data,
    }
  );
}

export const wrappedAxios = {
  get,
  post,
  put,
  patch,
  deleteCall
};