import
  React
from 'react';

import {
  CSSTransition
} from 'react-transition-group';

import {
  Colors,
  Image,
  Images
} from 'common';

import styled from 'styled-components';

import './modal.css';

// Styled-components for modal backdrop and content
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
`;

const ModalContent = styled.div`
  background: ${Colors.GreyBrownSecond};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.state === 'entering' || props.state === 'entered' ? 'scale(1)' : 'scale(0.9)')};
  opacity: ${(props) => (props.state === 'entering' || props.state === 'entered' ? 1 : 0)};
`;

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Modal = ({ show, onClose, children }) => {
  return (
    <Backdrop show={show} onClick={onClose}>
      <CSSTransition in={show} timeout={300} classNames={'modal'} unmountOnExit>
        {(state) => (
          <ModalContent state={state} onClick={(e) => e.stopPropagation()}>
            <CloseContainer>
              <Image als={'close'} src={Images.Icons.Close} width={'30px'} height={'auto'} onClick={onClose} />
            </CloseContainer>
            {children}
          </ModalContent>
        )}
      </CSSTransition>
    </Backdrop>
  );
};
