import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
} from 'common';

const Container = styled.div`
  position: ${props => props.position || 'relative'};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  border-radius: 10px;
  align-items: ${props => props.alignItems || `center`};
  background-color: ${props => props.backgroundColor || Colors.Black};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  overflow: hidden;

  ${props => props.showCursor && 'cursor: pointer;'}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}

  ${props => props.growOnHover && `
    transition: all .2s linear;
    :hover { transform: scale(1.1); }
  `}
`;

export const Card = props => {

  const onClick = () => {

    typeof props.onClick === 'function' && props.onClick(props.onClickArgs);
  };

  return (

    <Container
      position={props.position}
      alignItems={props.alignItems}
      backgroundColor={props.backgroundColor}
      flexDirection={props.flexDirection}
      padding={props.padding}
      margin={props.margin}
      height={props.height}
      width={props.width}
      growOnHover={props.growOnHover}
      boxShadow={props.boxShadow}
      showCursor={props.onClick === 'function'}
      onClick={onClick}>

        {props.children}
    </Container>
  );
};
