import
  React
from 'react';

/*import {
  Scanner
} from '@yudiel/react-qr-scanner';*/

import {
  withCommon,
} from 'common/hocs';

const EventCaptureBarcodeUI = props => {

  React.useEffect(() => {
    props.common.hideLoading();
  }, []); // eslint-disable-line

  return (
    //<Scanner onScan={(result) => console.log(result)} />
    <div>Scanner</div>
  );
};

export const EventCaptureBarcode = withCommon(EventCaptureBarcodeUI);