import
  React, {
  Component,
} from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  Image,
  Images,
} from 'common';

import {
  H2,
  PSmall,
  Loader,
} from 'common/components';

import {
  EventDetails,
} from './EventDetails';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow: hidden;
`;

const Header = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: linear-gradient(180deg, ${Colors.Black} 0%, ${Colors.Transparent} 100%);
  z-index: 1;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: auto;
`;

const ReactionContainer = styled.div`
  position: absolute;
  bottom: 60px;
  height: 40px;
  left: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
`;

const ReactionImage = styled.div`
  height: 35px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: ${props => props.alignItems};
`;

const SideContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  background: linear-gradient(${props => props.floatLeft ? '90deg' : '270deg'}, ${Colors.Black} 0%, ${Colors.Transparent} 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.floatLeft
      ? 'left: 0;'
      : 'right: 0;'}
`;

const SideImage = styled.div`
  height: 60px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomFade = styled.div`
  background: linear-gradient(0deg, ${Colors.Black} 45px, ${Colors.Transparent} 100%);
  height: 120px;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
`;

const EventDetailsSlide = styled.div`
  background-color: ${Colors.Black};
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${window.innerWidth}px;
  transition: left .4s ease-out;
  z-index: 2;
`;

const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export class EventSlide extends Component {

  state = {
    currentRightTouch: window.innerWidth,
  };

  initialRightTouchX = null;
  currentRightTouchX = null;

  initialRightTouchY = null;
  currentRightTouchY = null;

  video = React.createRef();

  onVideoClick = () => {

    if (!this.video || !this.video.current) {
      return;
    }

    if (this.video.current.paused) {

      this.video.current.play();
      return;
    }

    this.video.current.pause();
  };

  onEventDetailsBackClick = () => {

    this.video.current.play();

    this.setState({
      currentRightTouch: window.innerWidth
    });
  };

  onLocationClick = () => {
    typeof this.props.onLocationClick === 'function' && this.props.onLocationClick(this.props.onLocationClickArgs)
  };

  onRightTouchEnd = (e) => {

    if (this.state.currentRightTouch === 0) {

      e.stopPropagation();
      return;
    }

    const verticalMove = Math.abs(this.currentRightTouchY - this.initialRightTouchY);
    const horizontalMove = Math.abs(this.currentRightTouchX - this.initialRightTouchX);

    if (verticalMove > horizontalMove) {

      this.initialRightTouchX = null;
      this.currentRightTouchX = null;

      this.initialRightTouchY = null;
      this.currentRightTouchY = null;
      return;
    }

    let currentRightTouch;

    if ((this.initialRightTouchX - this.currentRightTouchX) < 20) {
      currentRightTouch = window.innerWidth;
    }

    if ((this.initialRightTouchX - this.currentRightTouchX) >= 20) {

      currentRightTouch = 0;
      this.video.current.pause();
    }

    this.initialRightTouchX = null;
    this.currentRightTouchX = null;

    this.initialRightTouchY = null;
    this.currentRightTouchY = null;

    this.setState({
      currentRightTouch,
    });
  };

  onRightTouchDrag = (e) => {

    if (!e || !e.touches || !e.touches.length) {
      return;
    }

    const clientX = e.touches[0].clientX;
    const clientY = e.touches[0].clientY;

    if (!this.initialRightTouchX) {
      this.initialRightTouchX = clientX;
    }

    if (!this.initialRightTouchY) {
      this.initialRightTouchY = clientY;
    }

    this.currentRightTouchX = clientX;
    this.currentRightTouchY = clientY;
  };

  componentDidUpdate(prevProps) {

    if (prevProps.play && !this.props.play) {
      this.video.current.pause();
    }

    if (!prevProps.play && this.props.play) {
      this.video.current.play();
    }
  }

  componentWillUnmount() {

    if (!this.video.current.paused) {
      this.video.current.pause();
    }
  }

  render () {

    return (

      <Container
        onTouchMove={this.onRightTouchDrag}
        onTouchEnd={this.onRightTouchEnd}>

        <LoaderContainer>

          <Loader/>

        </LoaderContainer>

        <Video
          src={this.props.videoUrl}
          controls={false}
          ref={this.video}
          onClick={this.onVideoClick}
          disableRemotePlayback={true}
          preload={'metadata'}
          autoPlay={this.props.autoPlay}
          loop={true}
          playsInline={true}
          muted={true}
        />

        <Header>

          <H2
            margin={'0 0 0.4em 0'}
            textAlign={'center'}>
              
              {this.props.event.name}
          </H2>

          <PSmall
            textAlign={'center'}>
            {this.props.event.eventLocation}
          </PSmall>

        </Header>

        <BottomFade/>

        <ReactionContainer>

          <ReactionImage
            alignItems={'flex-end'}>

            <Image
              height={'25px'}
              src={Images.Icons.Dislike}
            />

          </ReactionImage>

          <ReactionImage
            alignItems={'flex-start'}>

            <Image
              height={'25px'}
              src={Images.Icons.Like}
            />

          </ReactionImage>

        </ReactionContainer>

        <SideContainer
          floatLeft={true}
        />

        <SideContainer>

          <SideImage>

            <Image
              height={'25px'}
              src={Images.Icons.Location}
              onClick={this.onLocationClick}
            />

          </SideImage>

          <SideImage>

            <Image
              height={'auto'}
              width={'100%'}
              src={Images.Icons.Share}
            />

          </SideImage>

          <SideImage>

            <Image
              height={'25px'}
              src={Images.Icons.Watchlist}
            />

          </SideImage>

        </SideContainer>

        <EventDetailsSlide
          onTouchMove={(e) => e.stopPropagation()}
          style={{
            left: this.state.currentRightTouch
          }}>

            <EventDetails
              event={this.props.event}
              onBackClick={this.onEventDetailsBackClick}
            />

        </EventDetailsSlide>

      </Container>
    );
  }
};