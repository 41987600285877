import
  React, {
  Component,
} from 'react';

import
  styled
from 'styled-components';

import {
  P,
} from 'common/components';

import {
  EventSlide,
} from './EventSlide';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  ${props => props.padding && `padding: ${props.padding};`}
`;

const ShiftContainer = styled.div`
  position: relative;
  height: 100%;
  transition: top .4s ease-out;
`;

const ItemContainer = styled.div`
  position: relative;
  height: 100%;
`;

const NoItemContent = styled.div`
  position: relative;
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
`;

export class EventSwipe extends Component {

  shiftContainerRef = React.createRef();
  prevPosition = 0;

  initialRightTouchX = null;
  currentRightTouchX = null;

  initialRightTouchY = null;
  currentRightTouchY = null;

  screenPositionOnTouchStart = 0;

  state = {
    position: 0,
    dragPosition: -999,
    videoIndex: 0,
    items: [],
  };

  onTouchEnd = () => {

    if (this.state.dragPosition === -999) {
      return;
    }

    const verticalMove = Math.abs(this.currentRightTouchY - this.initialRightTouchY);
    const horizontalMove = Math.abs(this.currentRightTouchX - this.initialRightTouchX);

    if (horizontalMove > verticalMove) {

      this.initialRightTouchX = null;
      this.currentRightTouchX = null;

      this.initialRightTouchY = null;
      this.currentRightTouchY = null;

      this.setState({
        dragPosition: -999,
        position: this.screenPositionOnTouchStart,
      });

      return;
    }

    let containerHeight = this.shiftContainerRef.current.offsetHeight;
    let containerScrollHeight = this.shiftContainerRef.current.scrollHeight;
    let position = (-this.state.position);
    let remainder = position % containerHeight;
    let newPosition;
    let videoIndex = this.state.videoIndex;

    if ((containerScrollHeight - containerHeight) <= position) {
      newPosition = Math.floor(position / containerHeight) * containerHeight;
    }
    else if (this.prevPosition > this.state.dragPosition) {
      newPosition = (containerHeight - remainder) + position;
      
      if (videoIndex < this.state.items.length - 1) {
        videoIndex++;
      }
    }
    else {
      newPosition = Math.floor(position / containerHeight) * containerHeight;

      if (videoIndex > 0) {
        videoIndex--;
      }
    }

    this.initialRightTouchX = null;
    this.currentRightTouchX = null;

    this.initialRightTouchY = null;
    this.currentRightTouchY = null;

    this.setState({
      dragPosition: -999,
      position: -newPosition,
      videoIndex,
    }, () => {

      this.setState({
        items: this.createItems(),
      });
    });
  };

  onTouchDrag = (e) => {

    if (!e || !e.touches || !e.touches.length) {
      return;
    }

    const clientY = e.touches[0].clientY;
    const clientX = e.touches[0].clientX;

    if (!clientY || !clientX) {
      return;
    }

    if (!this.initialRightTouchY) {
      this.initialRightTouchY = clientY;
    }

    if (!this.initialRightTouchX) {
      this.initialRightTouchX = clientX;
    }

    this.currentRightTouchY = clientY;
    this.currentRightTouchX = clientX;

    if (this.state.dragPosition === -999) {

      this.setState({
        dragPosition: clientY,
      });

      return;
    }

    let newPosition = this.state.position - (this.state.dragPosition - clientY);

    if (newPosition >= 0) {
      newPosition = 0;
    }

    this.setState({
      position: newPosition,
      dragPosition: clientY,
    });
  };

  onTouchStart = (e) => {

    if (!e || !e.touches || !e.touches.length) {
      return;
    }

    this.prevPosition = e.touches[0].clientY;

    this.screenPositionOnTouchStart = this.state.position;
  };

  createItems = () => {

    const passedItems = this.props.events || [];
    let items = [];
    let item;

    for (let i = 0; i < passedItems.length; i++) {

      item = passedItems[i];

      if (!item || !item.defaultMedia || !item.defaultMedia.url) {
        continue;
      }

      items.push(

        <ItemContainer
          key={i}
          padding={this.props.itemPadding}>

            <EventSlide
              event={item}
              videoUrl={item.defaultMedia.url}
              autoPlay={i === 0}
              play={i === this.state.videoIndex}
              onLocationClick={this.props.onLocationClick}
              onLocationClickArgs={item}
            />

        </ItemContainer>
      );
    }

    if (!items.length) {

      items.push(
        <NoItemContent>

          <P>No ventures found for the current category</P>

        </NoItemContent>
      );
    }

    return items;
  }

  componentDidMount () {
    
    this.setState({
      items: this.createItems(),
    });
  }

  render () {

    return (

      <Container
        padding={this.props.padding}>

        <ShiftContainer
          ref={this.shiftContainerRef}
          onTouchMove={this.onTouchDrag}
          onTouchEnd={this.onTouchEnd}
          onTouchStart={this.onTouchStart}
          style={{
            top: this.state.position
          }}>

            {this.state.items}

        </ShiftContainer>

      </Container>
    );
  }
};