import {
  Home,
} from 'ui/home';

export const HomeRoutes = {
  home: {
    component: Home,
    path: '/home',
    default: true,
  },
};