import
  React, {
  Component,
} from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  Image,
  Images,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  Text,
} from 'common/components/text';

import {
  Button,
} from './Button';

import {
  UploadProvider,
} from 'providers';

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 10px 0'};
  width: ${props => props.width || '100%'};
`;

const UploadContainer = styled.div`
  background-color: ${Colors.GreyBrown};
  border-radius: 5px;
  color: ${Colors.LightGrey};
  font-size: ${FontSizes.Small};
  position: relative;
  width: 100%;
  padding: 10px;
`;

const FileListContainer = styled.div`
  padding: 0 0 10px 0;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 5px 0;
`;

const FileContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${props => props.width || '50%;'};
`;

const Percentage = styled.div`
  text-align: right;
  width: 100%;
  text-align: right;
`;

const UploadInput = styled.input`
  display: none;
`;

export class Upload extends Component {

  uploadInput = React.createRef();
  files = this.props.files || [];

  state = {
    fileElements: [],
  };

  onFileProgressChange = (file, e) => {

    if (!e || !e.loaded || !e.total) {
      return;
    }

    file.percentage = Math.round((e.loaded / e.total) * 100);

    this.setState({
      fileElements: this.createFileElements(),
    });
  };

  onChange = e => {

    if (!e || !e.target || !e.target.files) {
      return;
    }

    let currentFile;
    let file;

    for (let i = 0; i < e.target.files.length; i++) {

      currentFile = e.target.files[i];

      file = {
        file: currentFile,
        type: currentFile.type,
        size: currentFile.size,
        lastModifiedDate: currentFile.lastModifiedDate,
        name: currentFile.name,
        percentage: 0,
      };

      this.upload(file);

      this.files.push(file);
    }

    this.setState({
      fileElements: this.createFileElements(),
    });
  };

  onUploadClick = () => {
    this.uploadInput
    && this.uploadInput.current
    && typeof this.uploadInput.current.click === 'function'
    && this.uploadInput.current.click();
  };

  upload = (file) => {

    UploadProvider.upload(
      file,
      this.onFileProgressChange,
    )
    .then(ret => {

      if (isValidProviderResult(ret) && ret.data.url) {

        file.url = ret.data.url;

        typeof this.props.onUploadSuccess === 'function' && this.props.onUploadSuccess(file);
        return;
      }

      typeof this.props.onUploadFailed === 'function' && this.props.onUploadFailed(ret.message || Messages.FileUploadError);
    })
    .catch(e => {
      typeof this.props.onUploadFailed === 'function' && this.props.onUploadFailed((e && e.message) || Messages.FileUploadError);
    });
  };

  createFileElements = () => {

    let fileElements = [];
    let file;

    for (let i = 0; i < this.files.length; i++) {
      
      file = this.files[i];

      fileElements.push(
        <FileContainer key={i}>
          
          <FileContent
            width={'100%'}>
  
              {file.name}
          </FileContent>
  
          <FileContent
            width={'45px;'}>
              
            <Percentage>

              { file.percentage < 100

                ? `${file.percentage}%`

                : <Image
                    width={'15px'}
                    height={'auto'}
                    src={Images.Icons.Tick}
                  />
              }

            </Percentage>

          </FileContent>
  
        </FileContainer>
      );
    }

    return fileElements;
  };

  componentDidMount() {

    this.setState({
      fileElements: this.createFileElements(),
    });
  }

  componentDidUpdate(prevProps) {

    if (prevProps.files !== this.props.files) {
      
      this.files = this.props.files;

      this.setState({
        fileElements: this.createFileElements(this.props.files),
      });
    }
  }
  
  render () {

    return (

      <Container
        margin={this.props.margin}
        width={this.props.width}>

        { this.props.label &&
        
          <Text
            fontSize={FontSizes.Small}
            padding={'0 0 10px 0'}>

              {this.props.label}
          </Text>
        }

        <UploadContainer>
          
          <UploadInput
            ref={this.uploadInput}
            type={'file'}
            onChange={this.onChange}
          />

          <FileListContainer>

            { this.state.fileElements.length

              ? this.state.fileElements

              : 'No files uploaded'
            }

          </FileListContainer>

          <Button
            backgroundColor={Colors.LightGreyBrown}
            borderRadius={'5px'}
            text={'UPLOAD DOCUMENTS'}
            width={'100%'}
            onClick={this.onUploadClick}
          />

        </UploadContainer>
        
      </Container>
    );
  }
};