import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  Images,
} from 'common';

import {
  P,
} from 'common/components/text';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width || '90%'};
  margin: 0 auto;
`;

const Item = styled.div`
  background-color: ${props => props.fill ? Colors.PrimaryOne : Colors.Transparent};
  border: 2px solid ${Colors.PrimaryOne};
  border-radius: 15px;
  color: ${props => props.fill ? Colors.White : Colors.PrimaryOne};
  font-size: ${FontSizes.Smaller};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  position: relative;
  text-align: center;

  ${props => props.showImage && `
    background-image: url(${Images.Icons.Tick});
    background-size: 45%;
    background-position: center;
    background-repeat: no-repeat;
  `}

  &:after {
    color: ${Colors.White};
    content: "${props => props.text}";
    position: absolute;
    top: 40px;
  }
`;

const Line = styled.div`
  background-color: ${Colors.GreyBrown};
  height: 1px;
  display: flex;
  flex: 1;
  margin: 0 2%;
`;

export const Stepper = props => {

  let propItems = props.items || [];
  let items = [];
  let item;
  let isCompleted;
  let isCompletedOrBusy;
  let content;

  for (let i = 0; i < propItems.length; i++) {

    item = propItems[i];

    isCompleted = i < props.selectedIndex;

    isCompletedOrBusy = (i <= props.selectedIndex)
      ? 1
      : 0;

    content = isCompleted
      ? ''
      : i + 1;

    if (i === propItems.length - 1) {

      items.push(
        <Item
          key={i}
          text={item.text}
          fill={isCompletedOrBusy}
          showImage={isCompleted}>
            {content}
        </Item>
      );

      break;
    }

    items.push(
      <React.Fragment key={i}>

        <Item
          text={item.text}
          fill={isCompletedOrBusy}
          showImage={isCompleted}>
            {content}
        </Item>

        <Line/>

      </React.Fragment>
    );
  }

  return (

    <Container
      width={props.width}>

      { items.length > 0
        
        ? items
        
        : <P>No items to display</P>
      }

    </Container>
  );
};
