import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
} from 'common';

const Container = styled.div`
  cursor: pointer;
  height: ${props => props.size || '30px'};
  left: 50%;
  position: relative;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(${props => props.degrees}deg);
  transition: transform 0.1s;
  width: ${props => props.size || '30px'};

  :active {
    transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(${props => props.degrees}deg);
  }
`;

const ArrowTop = styled.div`
  background-color: ${props => props.active ? Colors.White : Colors.DarkGrey};
  height: 2px;
  left: -5px;
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
  transform: rotate(45deg);
  transform-origin: bottom right;

  :after {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
    left: 100%;
    right: 0;
    transition-delay: 0s;
  }
`;

const ArrowBottom = styled.div`
  background-color: ${props => props.active ? Colors.White : Colors.DarkGrey};
  height: 2px;
  left: -5px;
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
  transform: rotate(-45deg);
  transform-origin: top right;

  :after {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
  }
`;

export const Arrow = props => {

  const onArrowClick = () => {

    if (!props.active) {
      return;
    }

    typeof props.onClick === 'function' && props.onClick();
  };

  const degrees = props.direction === 'left'
    ? '180'
    : props.direction === 'up'
      ? '270'
      : props.direction === 'down'
        ? '90'
        :'0';

  return (

    <Container
      degrees={degrees}
      size={props.size}
      onClick={onArrowClick}>

      <ArrowTop
        active={props.active}
      />

      <ArrowBottom
        active={props.active}
      />

    </Container>
  );
};