import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  ContainerWithHeader,
  DeviceInfo,
  FontFamilies,
} from 'common';

import {
  UserMenu,
  H1,
  Loader,
  UserMessage,
} from 'common/components';

const Container = styled(ContainerWithHeader)`
  display: flex;
  ${props => !props.isMobile && 'padding: 0;'}
`;

const SideMenuContainer = styled.div`
  width: 280px;
  display: flex;
  position: relative;
`;

const ContentContainer = styled.div`
  ${props => !props.isMobile && 'padding: 45px 15px 15px 15px;'}
  overflow: auto;
  width: 100%;
`;

const FormContainer = styled.div`
  ${props => props.addPadding && `padding: 70px 0 15px 0;`}
  ${props => !props.show && `display: none;`}
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

export const SideMenuStepperScreen = props => {

  return (

    <Container>

      { !DeviceInfo.IsMobile &&

        <SideMenuContainer>

          <UserMenu />

        </SideMenuContainer>
      }

      <ContentContainer
        id={'side-menu-container'}
        isMobile={DeviceInfo.IsMobile}>

        <H1
          fontFamily={FontFamilies.Bold}
          margin={'0 0 30px 15px'}>

          {props.title}
        </H1>

        {props.stepper}

        { props.isLoading &&

          <LoaderContainer>

            <Loader/>

          </LoaderContainer>
        }

        { !props.isLoading && props.showUserMessage &&

          <UserMessage
            margin={'70px 15px 15px 15px'}
            text={props.userMessage}
          />
        }

        <FormContainer
          show={!props.isLoading}
          addPadding={!props.showUserMessage}>

            {props.children}
        </FormContainer>

      </ContentContainer>

    </Container>
  );
};
