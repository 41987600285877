import {
  EventDetails,
  EventTicketSelect,
  EventTicketIdentity,
  EventPayment,
  EventPaymentResult,
  EventCaptureBarcode,
  EventMerchSelect,
} from 'ui/event';

export const EventRoutes = {
  /*eventsHome: {
    component: EventsHome,
    path: '/event/home',
  },
  eventMap: {
    component: EventMap,
    path: '/event/map',
  },
  eventCreateDetails: {
    component: EventCreateDetails,
    path: '/event/create/details',
  },
  eventCreateOptions: {
    component: EventCreateOptions,
    path: '/event/create/options',
  },
  eventCreateFinancials: {
    component: EventCreateFinancials,
    path: '/event/create/financials',
  },
  eventCreateConfirm: {
    component: EventCreateConfirm,
    path: '/event/create/confirm',
  },
  eventCreateResult: {
    component: EventCreateResult,
    path: '/event/create/result',
  },*/
  eventDetails: {
    component: EventDetails,
    path: '/event/details/:id',
  },
  eventTicketSelect: {
    component: EventTicketSelect,
    path: '/event/ticket/select/:id',
  },
  eventTicketIdentity: {
    component: EventTicketIdentity,
    path: '/event/ticket/identity',
  },
  eventPayment: {
    component: EventPayment,
    path: '/event/payment',
  },
  eventPaymentResult: {
    component: EventPaymentResult,
    path: '/payment-result/:result',
  },
  eventCaptureBarcode: {
    component: EventCaptureBarcode,
    path: '/event/capture-barcode',
  },
  eventMerchSelect: {
    component: EventMerchSelect,
    path: '/event/merch/select',
  }
};