import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class OrderProvider {

  static async createOrder(id, tickets, merch) {

    try {

      let url = `${Environment.apiHost}tickets/order`;

      let res = await axios.post(url, {
        tickets,
        merch,
        event_id: id
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getOrderByOrderId(id, result) {

    try {

      let res = await axios.get(`${Environment.apiHost}tickets/order-by-id?orderId=${id}&result=${result}`);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}

