import {
  useEffect
} from "react";

import {
  Images
} from "common";

import
  styles
from "./Toast.module.css";

export const Toast = ({
  show,
  message,
  type = "error",
  duration = 4000,
  onClose,
  offset = 50
}) => {

  useEffect(() => {

    const timer = setTimeout(() => {

      if (typeof onClose === "function") {

        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [show, duration, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.toast} ${styles[type]}`}>
        {message}
        <img
          alt={"close"}
          style={{ margin: "0 0 0 20px", width: "18px", height: "18px" }}
          onClick={onClose}
          src={Images.Icons.Close}
        />
      </div>
    </div>
  );
};
