import {
  LogProvider
} from 'providers';

export class Logger {

  static error(
    component = '',
    method = '',
    ex
  ) {

    LogProvider.error(component, method, ex)
      .catch(e => console.log(e));
  }
}