import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  MediaQueries,
} from 'common';

export const Container = styled.div`
  display: flex;

  @media only screen and ${MediaQueries.Md} {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    flex: 1;
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

export const Content = styled.div`
  flex: 1;
  background-color: ${props => props.backgroundColor || Colors.LightGreyBrown};
  position: relative;
  min-height: 100vh;

  @media only screen and ${MediaQueries.Md} {
    min-height: auto;
    background-color: ${props => props.desktopBackgroundColor || Colors.LightGreyBrown};
  }
`;

export const MobileContentSpace = styled.div`
  height: ${props => props.height || '40vh'};
  position: relative;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

export const SideContentContainer = styled.div`
  display: none;
    
  @media only screen and ${MediaQueries.Md} {
    min-width: 390px;
    height: 100%;
    display: block;
    margin: 0 0 0 20px;
  }
`;

export const SideContent = styled.div`
  display: none;
  
  @media only screen and ${MediaQueries.Md} {
    background-color: ${Colors.GreyBrown};
    display: block;
    border-radius: 10px;
    margin: ${props => props.marginTop || '50px'} 0 0 0;
    position: -webkit-sticky;
    position: sticky;
    top: ${props => props.top || '50px'};
    overflow: hidden;
    ${props => props.padding && `padding: ${props.padding};`}
  }
`;

export const Header = styled.div`
  background-color: ${Colors.Black};
  color: ${Colors.White};
  font-family: ${FontFamilies.Bold};
  font-size: ${FontSizes.Bigger};
  padding: 10px 15px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  position: relative;
`;

export const MainImage = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 40vh;
  position: fixed;
  top: 8px;
  margin: 10px 0 0 0;

  @media only screen and ${MediaQueries.Md} {
    position: relative;
    top: 0;
  }
`;

export const DetailsText = styled.div`
  padding: 5px 20px 20px 20px;
`;

export const DetailContainer = styled.div`
  background-color: ${props => props.backgroundColor || Colors.GreyBrown};
  border-top: 1px solid ${props => props.borderColor || Colors.LightGreyBrown};
  display: flex;
  align-items: center;
  padding: ${props => props.padding || '10px 20px'};
  position: relative;
  width: 100%;
`;

export const DetailIcon = styled.div`
  background-image: url(${props => props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 22px;
`;

export const DetailContent = styled.div`
  color: ${Colors.White};
  padding: ${props => props.padding || '0 0 0 15px'};
  width: ${props => props.width || '100%'};
`;

export const DetailContentText = styled.div`
  font-size: ${props => props.fontSize || FontSizes.Regular};
  padding: ${props => props.padding || '0'};
  
  ${props => props.onClick && 'text-decoration: underline; cursor: pointer;'}
  ${props => props.bold && `font-family: ${FontFamilies.Bold};`}
`;

export const ActionContainer = styled.div`
  background-color: ${Colors.GreyBrown};
  border-top: 1px solid ${Colors.LightGreyBrown};
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

export const FullWidthContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StepperContainer = styled.div`
  padding: 0 20px 50px 20px;
  max-width: 650px;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    width: 50vw;
  }
`;

export const MaxWidthContainer = styled.div`
  padding: 0 20px 20px 20px;
  max-width: 650px;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    width: 50vw;
  }
`;

export const EventName = styled.div`
  color: ${Colors.White};
  font-size: ${FontSizes.Bigger};
  font-family: ${FontFamilies.Bold};
  padding: 70px 0 6px 0;
  text-align: center;
  width: 100%;
`;
