import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
} from 'common';

import {
  Loader,
} from './Loader'

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.fontSize || FontSizes.Small};
  height: ${props => props.height || '40px'};
  border-radius: ${props => props.borderRadius || '10px'};
  width: ${props => props.width || '250px'};
  user-select: none;

  ${props => props.fontFamily && `font-family: ${props.fontFamily};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.border && `border: ${props.border};`}

  background-color: ${props => !props.disabled
    ? props.backgroundColor || Colors.PrimaryOne
    : Colors.Grey};

    color: ${props => !props.disabled
      ? Colors.White
      : Colors.Black};

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

export const Button = props => {

  const onClick = () => {

    if (props.disabled || props.showLoading) {
      return;
    }

    typeof props.onClick === 'function' && props.onClick();
  };

  return (

    <Container
      backgroundColor={props.backgroundColor}
      flexDirection={props.flexDirection}
      height={props.height}
      width={props.width}
      fontFamily={props.fontFamily}
      fontSize={props.fontSize}
      border={props.border}
      borderRadius={props.borderRadius}
      margin={props.margin}
      disabled={props.disabled || props.showLoading}
      onClick={onClick}>

        { props.showLoading

          ? <Loader
              size={35}
            />

          : props.text || props.children || ''
        }

    </Container>
  );
};