import
  React
from 'react';

import
  styled, {
  keyframes,
} from 'styled-components';

const RippleAnimation = () => keyframes`
  0% {
   transform: scale(1);
   box-shadow: 0 0 100px 20px $FFFFFF;
  }
  100% {
   transform: scale(1.5);
   box-shadow: 0 0 100px 20px #000;
  }
`;

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: grid;
  place-items: center;
  animation: ${RippleAnimation} 0.8s infinite alternate-reverse;
  position: relative;
`;

const Ripple = styled.div`
  background-color: #212121;
  width: 85%;
  height: 85%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = props => (

   <Container>
      <Ripple>
      <svg style={{ width: '40px', height: '40px' }} width="130" height="132" viewBox="0 0 130 132" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0787 43.3911L15.5708 41.3952H24.3739L32.6549 82.7904H33.4755L42.3533 46.274H52.947L61.8248 82.7904H62.6454L70.9264 41.3952H79.7295L81.2216 43.3911L71.1502 92.3999H54.066L47.6501 65.4192L41.2343 92.3999H24.1501L14.0787 43.3911Z" fill="white"/>
        <path d="M89.432 92.3999V41.3952H124.645V51.0048H100.175V61.3536H122.407V70.9632H100.175V82.7904H124.645V92.3999H89.432Z" fill="white"/>
        <path d="M126.126 83.0279C128.6 83.7357 130.042 86.304 129.156 88.6999C124.174 102.178 114.873 113.724 102.614 121.528C89.0425 130.167 72.7689 133.633 56.814 131.282C40.8591 128.932 26.307 120.925 15.8596 108.747C5.4122 96.5703 -0.220749 81.0506 0.00662398 65.0697C0.233997 49.0887 6.30624 33.7325 17.0959 21.852C27.8857 9.97151 42.6597 2.37402 58.6751 0.470114C74.6906 -1.43379 90.8591 2.48524 104.179 11.4996C116.211 19.6427 125.181 31.444 129.777 45.0561C130.594 47.4758 129.08 50.0028 126.587 50.6412C124.093 51.2797 121.568 49.7862 120.723 47.3759C116.71 35.928 109.086 26.0103 98.9193 19.1296C87.4643 11.3773 73.5593 8.00694 59.786 9.6443C46.0128 11.2817 33.3071 17.8155 24.0279 28.0327C14.7488 38.2499 9.52664 51.4563 9.3311 65.1999C9.13556 78.9435 13.9799 92.2905 22.9647 102.763C31.9495 113.235 44.4642 120.121 58.1854 122.143C71.9066 124.164 85.902 121.183 97.573 113.754C107.932 107.16 115.835 97.4594 120.172 86.1282C121.085 83.7424 123.652 82.32 126.126 83.0279Z" fill="white"/>
      </svg>
      </Ripple>
    </Container>
);