import
  styled
from 'styled-components';

import {
  Colors,
} from './constants';

import {
  DeviceInfo,
} from './device';

export const ContainerWithHeader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 15px 15px 15px;
  background-color: ${Colors.Black};
  overflow: ${props => props.overflow || 'auto'};
`;

export const InputContainer = styled.div`
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}

  ${props => props.full || DeviceInfo.IsMobile
    ? `width: 100%;`
    : `width: 50%;`
  }
`;

export const Image = styled.img`
  height: ${props => props.height || '100px'};
  width: ${props => props.width || 'auto'};
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.onClick && `cursor: pointer;`}

  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;