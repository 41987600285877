import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  H2,
  PSmall,
} from 'common/components/text';

import {
  Arrow,
} from 'common/components/Arrow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.padding && `padding: ${props.padding};`}
`;

const ContentContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ShiftContainer = styled.div`
  display: flex;
  position: relative;
  transition: left .2s ease-out;
`;

const ItemContainer = styled.div`
  padding: ${props => props.padding || '0 15px 0 0'};
`;

const HeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ArrowContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0 10px 0 0;
`;

const Space = styled.div`
  width: 15px;
`;

export const Carousel = props => {

  const shiftContainerRef = React.createRef();

  const [state, setState] = React.useState({
    position: 0,
    dragPosition: -999,
  });

  const onTouchEnd = () => {

    setState(prevState => ({
      ...prevState,
      dragPosition: -999,
    }));
  };

  const onTouchDrag = (e) => {

    if (!e || !e.touches || !e.touches.length) {
      return;
    }

    const clientX = e.touches[0].clientX;

    if (!clientX) {
      return;
    }

    if (state.dragPosition === -999) {

      setState(prevState => ({
        ...prevState,
        dragPosition: clientX,
      }));

      return;
    }

    let newPosition = state.position - (state.dragPosition - clientX);

    if (newPosition >= 0) {
      newPosition = 0;
    }

    setState(prevState => ({
      ...prevState,
      position: newPosition,
      dragPosition: clientX,
    }));
  };

  const onLeftArrowClick = () => {

    setState(prevState => ({
      ...prevState,
      position: prevState.position + 150,
    }));
  };

  const onRightArrowClick = () => {

    setState(prevState => ({
      ...prevState,
      position: prevState.position - 150,
    }));
  };

  const passedItems = props.children || [];
  let items = [];

  for (let i = 0; i < passedItems.length; i++) {

    items.push(

      <ItemContainer
        key={i}
        padding={props.itemPadding}>

          {passedItems[i]}
      </ItemContainer>
    );
  }

  const isRightMax = state.containerWidth >= -(state.position);

  const isLeftMax = state.position >= 0;

  return (

    <Container
      padding={props.padding}>

      <HeadingContainer>

        { props.heading &&

          <div>

            <H2
              margin={props.subHeading && '0.8em 0 0.2em 0'}>
                
                {props.heading}
            </H2>

            { props.subHeading &&

              <PSmall
                padding={'3px 0 18px 0'}>

                  {props.subHeading}
              </PSmall>
            }

          </div>
        }

        { !props.hideArrows &&

          <ArrowContainer>

            <Arrow
              active={!isLeftMax}
              direction={'left'}
              size={'10px'}
              onClick={onLeftArrowClick}
            />

            <Space/>

            <Arrow
              active={!isRightMax}
              size={'10px'}
              onClick={onRightArrowClick}
            />

          </ArrowContainer>
        }

      </HeadingContainer>

      <ContentContainer>

        <ShiftContainer
          ref={shiftContainerRef}
          onTouchMove={onTouchDrag}
          onTouchEnd={onTouchEnd}
          style={{
            left: state.position,
          }}>

            {items}
        </ShiftContainer>

      </ContentContainer>

    </Container>
  );
};