import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class UploadProvider {

  static async upload(
    file,
    progressCallback
  ) {
    
    const formData = new FormData();

    formData.append('file', file.file);

    try {

      let res = await axios.post(
        `${Environment.apiHost}api/upload/file`,
        formData, {
          onUploadProgress: (progressEvent) => { progressCallback(file, progressEvent) }
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}