import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Card,
} from './Card';

const Video = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${props => props.isHidden && `display: none;`}
`;

const Thumbnail = styled.div`
  background-image: url(${props => props.src});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  ${props => props.isHidden && `display: none;`}
`;

export const VideoCard = props => {

  const [isPlaying, isPlayingSetState] = React.useState(false);

  const video = React.createRef();

  const onVideoClick = () => {

    isPlaying
      ? video.current.pause()
      : video.current.play();

    isPlayingSetState(!isPlaying);
  };

  return (

    <Card
      height={props.height}
      width={props.width}
      growOnHover={props.growOnHover || true}>

      <Thumbnail
        src={props.thumbnail}
        isHidden={isPlaying}
        onClick={onVideoClick}
      />
      
      <Video
        src={props.src}
        isHidden={!isPlaying}
        onClick={onVideoClick}
        ref={video}
      />
      
    </Card>
  );
};