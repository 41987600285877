import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Images,
} from 'common';

const Container = styled.div`
  position: fixed;
  background-color: #1F1F1F;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding: 10px 5%;
  z-index: 1;
`;

const LogoImage = styled.img`
  height: 100%;
  width: auto;
`;

export const Footer = props => {

  return (

    <Container>

      <LogoImage
        src={Images.Logo}
      />

    </Container>
  );
};