import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

import {
  Colors,
  FontFamilies,
  FontSizes
} from 'common';

export const Markdown = ({ markdown, defaultFontSize, padding, gap }) => {
  const renderedContent = useMemo(() => {
    // Basic markdown conversion without external libraries
    const html = (markdown ?? '')
      // Headers
      .replace(/^# (.*$)/gim, '<h1>$1</h1>')
      .replace(/^## (.*$)/gim, '<h2>$1</h2>')
      .replace(/^### (.*$)/gim, '<h3>$1</h3>')

      // Bold
      .replace(/\*\*(.*?)\*\*/g, `<span style="font-family: ${FontFamilies.Bold}">$1</span>`)

      // Italic
      .replace(/\*(.*?)\*/g, '<em>$1</em>')

      // Code inline
      // .replace(/`(.*?)`/g, '<code>$1</code>')

      // Code blocks
      // .replace(/```(\w*)\n([\s\S]*?)```/g, 
        // '<pre><code class="language-$1">$2</code></pre>')

      // Links
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, 
        `<a class="white" style="font-size: ${defaultFontSize ?? FontSizes.Small}" href="$2" target="_blank">$1</a>`)

      // Bullet points (unordered lists)
      .replace(/^\- (.*$)/gim, '<li>$1</li>') // eslint-disable-line
      .replace(/(<li>.*<\/li>\n?)+/g, `<ul class="markdown-ul" style="font-size: ${defaultFontSize ?? FontSizes.Small}">$&</ul>`)
      
      // Paragraphs
      .replace(/^(?!<)(.+)$/gim, `<p style="line-height: 1.5; font-size: ${defaultFontSize ?? FontSizes.Small}">$1</p>`);

    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'code', 'pre', 'a', 'span', 'ul', 'li'],
      ALLOWED_ATTR: ['href', 'class', 'style', 'target']
    });
  }, [markdown, defaultFontSize]);

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: gap ?? '20px',
        color: Colors.White,
        padding: padding ?? '0',
        fontSize: defaultFontSize ?? FontSizes.Small,
        lineHeight: 1.5,
      }}
      dangerouslySetInnerHTML={{ __html: renderedContent }}
    />
  );
};
