import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontSizes,
  Image,
  Images,
} from 'common';

import {
  Text,
} from 'common/components';

import {
  Version,
} from 'generated/version';

import {
  IdentityProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.GreyBrown};
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0 20px 20px 20px;
  overflow: auto;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
`;

const UserMenuComponent = props => {

 // const [_, menuOptionsSetState] = React.useState({});

  const onLogoutClick = () => {

    IdentityProvider
      .logout()
      .then(ret => {
        props.history.push('/identity/login');
      })
      .catch(e => {

        // TODO: log to sentry
        props.history.push('/identity/login');
      });    
  };

  const onCloseClick = () => {
    typeof props.onCloseClick === 'function' && props.onCloseClick();
  };

  /*const onLinkClick = path => {

    if (!path) {
      return;
    }

    props.history.push(path);
  };*/

  /*const loadUserMenuOptions = async () => {

    try {
      return {};

      /*let ret = await UserMenuProvider.getMenuOptions();

      if (!isValidProviderResult(ret) || !ret.data.userMenu) {

        return {};
      }

      DataStore.set(
        'userMenu',
        ret.data.userMenu,
      );

      DataStore.set(
        'isLoggedIn',
        ret.data.isLoggedIn,
      );

      return ret.data.userMenu;

    } catch (e) {

      // TODO: log to sentry
      return {};
    }
  };*/

  React.useEffect(() => {
/*
    const checkMenuItems = async () => {

      const menuItems = DataStore.get('userMenu');

      let userMenuItems = menuItems || await loadUserMenuOptions();
  
      menuOptionsSetState(userMenuItems);
    };

    checkMenuItems()
      .catch(e => {

        // TODO: log to sentry
      });*/

  }, []);

  const menuItemElements = [];
  const isLoggedIn = DataStore.get('isLoggedIn');

  /*for (const [key, value] of Object.entries(menuOptions)) {

    if (!value.items || !value.items.length) {
      continue;
    }

    menuItemElements.push(
      <Text
        key={key}
        color={Colors.PrimaryOne}
        padding={'30px 0 0 0'}
        display={'block'}>

          {value.display || 'unknown'}
      </Text>
    );

    let item;

    for (let i = 0; i < value.items.length; i++) {

      item = value.items[i];

      if (!item || !item.display) {
        continue;
      }

      const itemPath = item.path;

      menuItemElements.push(
        <Text
          key={`${key}-${i}`}
          fontSize={FontSizes.Small}
          padding={'25px 0 0 0'}
          display={'block'}
          onClick={() => { onLinkClick(itemPath) }}>

            {item.display}
        </Text>
      );
    }
  }*/

  const logoPadding = props.showClose
    ? '65px 28px 15px 28px'
    : '45px 28px 15px 28px';

  return (

    <Container>
      
      { props.showClose &&

        <CloseContainer>

          <Image
            height={'100%'}
            src={Images.Icons.Close}
            onClick={onCloseClick}
          />

        </CloseContainer>
      }

      <Image
        src={Images.Logo}
        width={'100%'}
        height={'auto'}
        maxHeight={'110px'}
        padding={logoPadding}
      />

      <Text
        fontSize={FontSizes.Smallest}
        padding={'5px 0'}
        width={'100%'}
        textAlign={'center'}>
          
          {`Version ${Version}`}
      </Text>

      { (menuItemElements && menuItemElements.length && menuItemElements) ||
      
        <Text
          color={Colors.White}
          padding={'30px 0 0 0'}
          display={'block'}
          textAlign={'center'}>

            {'No items to display'}
        </Text>
      }

      { isLoggedIn &&

        <Text
          fontSize={FontSizes.Small}
          padding={'25px 0 0 0'}
          display={'block'}
          onClick={onLogoutClick}>

            Logout
        </Text>
      }

    </Container>
  );
};

export const UserMenu = withRouter(UserMenuComponent);