import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  FontFamilies,
} from 'common';

import {
  Card,
  Text,
  TextInput,
} from 'common/components';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 15px;
`;

const Line = styled.div`
  background-color: ${Colors.GreyBrown};
  height: 2px;
  width: 100%;
  margin: 0 0 15px 0;
`;

export const EventModelCard = props => {

  const onClick = () => {
    typeof props.onClick === 'function' && props.onClick(props.onClickArgs);
  };

  return (

    <Card
      alignItems={'flex-start'}
      margin={props.margin || '0 0 15px 0'}
      width={props.width}
      height={props.height}
      onClick={onClick}
      backgroundColor= {props.isSelected ? Colors.PrimaryOne : Colors.LightGreyBrown}>
        
        <ContentContainer>

          <Text
            fontFamily={FontFamilies.Bold}
            fontSize={FontSizes.Regular}
            padding={'0 0 15px 0'}>

            {props.text}
          </Text>

          <Text
            fontSize={FontSizes.Small}
            padding={'0 0 15px 0'}>

            {props.description}
          </Text>

          { props.showPercentageInput &&

            <React.Fragment>

              <Line/>

              <Text
                fontSize={FontSizes.Small}
                padding={'0 0 15px 0'}>

                {props.precentageLabel || 'Risk Percentage'}
              </Text>

              <TextInput
                type={'number'}
                placeholder={'ENTER PERCENTAGE'}
                margin={'0'}
                width={'100%'}
                postFix={'%'}
                onChange={props.onPercentageChange}
                defaultValue={props.percentageDefaultValue}
              />

            </React.Fragment>
          }

        </ContentContainer>

    </Card>
  );
};