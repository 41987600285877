import
  React, {
  Component,
} from 'react';

/*
import
  GoogleMapReact
from 'google-map-react';
*/

export class Map extends Component {

  render = () => {

    return (
      <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: '#d2151b'
      }}></div>
      /*<GoogleMapReact
        bootstrapURLKeys={{ key: '' }}
        defaultCenter={{
          lat: -33.9554,
          lng: 18.5879
        }}
        defaultZoom={11}
        options={{
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }}
      />*/
    );
  };
}