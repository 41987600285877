import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class IdentityProvider {

  static async login(
    email,
    password,
    rememberMe = false,
  ) {
    
    try
    {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!password) {

        return {
          ok: false,
          message: 'Please provide a valid password',
          data: null,
        };
      }

      let res = await axios.post(
        `${Environment.apiHost}api/identity/login`, {
          email,
          password,
          rememberMe,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async register(
    email,
    password,
    confirmPassword,
    newsLetterSignup
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!password) {

        return {
          ok: false,
          message: 'Please provide a valid password',
          data: null,
        };
      }

      if (!confirmPassword) {

        return {
          ok: false,
          message: 'Please confirm your password',
          data: null,
        };
      }

      let res = await axios.post(
        `${Environment.apiHost}api/identity/register`, {
          email,
          password,
          confirmPassword,
          newsLetterSignup,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async logout() {
    
    try
    {

      let res = await axios.post(
        `${Environment.apiHost}api/identity/logout`
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}

