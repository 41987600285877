import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  MediaQueries
} from 'common';

import {
  Button,
  Text
} from 'common/components';

const Container = styled.div`
  padding-bottom: 6vmin;
  min-width: 100%;
  z-index: 1;

  @media only screen and ${MediaQueries.Md} {
    min-width: 350px;
    max-width: 450px;
  }
`;

const Heading = styled.div`
  color: ${Colors.White};
  font-family: ${FontFamilies.Bold};
  font-size: ${FontSizes.Biggest};
  padding-bottom: 5px;
  text-align: right;
`;

export const MainEventDetailsBlock = props => {

  return (

    <Container>

      <Heading>{props.name}</Heading>

      <Text
        textAlign={'right'}
        display={'block'}
        padding={'0 0 5px 0'}>
          {props.location}
      </Text>

      <Text
        textAlign={'right'}
        display={'block'}
        padding={'0 0 5px 0'}>
          {props.date}
      </Text>

      <span style={{display: 'flex', justifyContent: 'flex-end'}}>

      { props.pricePrefix &&

        <Text
          textAlign={'right'}
          display={'block'}
          padding={'0 5px 15px 0'}
          fontSize={FontSizes.Smaller}
          fontFamily={FontFamilies.Regular}>
            {props.pricePrefix}
        </Text>
      }
      <Text
        textAlign={'right'}
        display={'block'}
        padding={'0 0 15px 0'}
        fontFamily={FontFamilies.Bold}>
          {props.price}
      </Text>
      </span>

      { !props.hideBuyButton &&

        <Button
          disabled={props.isSoldOut}
          width={'100%'}
          onClick={() => props.onGetTicketClick && props.onGetTicketClick()}>
          { props.isSoldOut ? 'Sold Out' : 'Get Tickets' }
        </Button>
      }

    </Container>
  );
};