import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
} from 'common';

const Container = styled.div`
  color: ${Colors.White};
  font-size: ${FontSizes.Small};
`;

const StyledTable = styled.table`
  width: 100%;
`;

const Td = styled.td`
  padding: 5px;
`;

export const Table = props => {

  if (!Array.isArray(props.data) || !props.data.length) {

    return (
      <Container>No items to display</Container>
    );
  }

  let rows = [];
  let cols;

  for (let i = 0; i < props.data.length; i++) {

    if (!props.data) {
      return;
    }

    cols = [];

    for (const [key, val] of Object.entries(props.data[i])) {

      cols.push(
        <Td key={`${key}-${i}`}>{val}</Td>
      );
    }

    rows.push(
      <tr key={`row-${i}`}>{cols}</tr>
    );
  }

  return (

    <Container>

      <StyledTable>

        <tbody>

          {rows}
          
        </tbody>

      </StyledTable>

    </Container>
  );
};