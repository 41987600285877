import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class PaymentProvider {

  static async checkout(orderId) {

    try {

      let url = `${Environment.apiHost}payment/checkout`;

      let res = await axios.post(url, {
        orderId: orderId
      });

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async postEventReservation(
    eventId,
    reservationCount,
    returnUrl,
    redirectURL
  ) {

    try {

      if (!eventId) {

        return {
          ok: false,
          message: 'Please provide a valid eventId',
          data: null,
        };
      }

      if (!reservationCount) {

        return {
          ok: false,
          message: 'Please provide a valid reservationCount',
          data: null,
        };
      }

      if (!returnUrl) {

        return {
          ok: false,
          message: 'Please provide a valid returnUrl',
          data: null,
        };
      }
      
      if (!redirectURL) {

        return {
          ok: false,
          message: 'Please provide a valid redirectURL',
          data: null,
        };
      }

      let res = await axios.post(
        `${Environment.apiHost}api/EventReservations`, {
          EventId: eventId,
          ReservationCount: reservationCount,
          ReturnUrl: returnUrl,
          RedirectURL: redirectURL,
        },
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async postVentureCreatePayment(
    eventId,
    returnUrl,
    redirectURL
  ) {

    try {

      if (!eventId) {

        return {
          ok: false,
          message: 'Please provide a valid eventId',
          data: null,
        };
      }

      if (!returnUrl) {

        return {
          ok: false,
          message: 'Please provide a valid returnUrl',
          data: null,
        };
      }
      
      if (!redirectURL) {

        return {
          ok: false,
          message: 'Please provide a valid redirectURL',
          data: null,
        };
      }

      let res = await axios.post(
        `${Environment.apiHost}api/events/EventCreatePayment`, {
          EventId: eventId,
          ReturnUrl: returnUrl,
          RedirectURL: redirectURL,
        },
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}