import
  React
from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";

/*import {
  isValidProviderResult,
} from 'common';*/

import {
  Logger
} from 'common';

import {
  Routes,
} from 'navigation';

/*import {
  VersionProvider,
} from 'providers';*/

function App() {

  /*React.useEffect(() => {

    VersionProvider
      .getLatestVersion()
      .then(ret => {

        if (!isValidProviderResult(ret)) {
        
          // TODO: log to sentry
          return;
        }
      })

  }, []);*/

  let routes = [];
  let route;

  for (const key in Routes) {

    route = Routes[key];

    if (route.default) {

      routes.push(
        <Route exact path={'/'} key={'default-path'}>
          <Redirect to={route.path} />
        </Route>
      );
    }

    routes.push(
      <Route
        key={key}
        component={route.component}
        path={route.path}
        exact={true}
      />
    );
  }

  return (

    <ErrorBoundary fallback={<div></div>} onError={(error, info) => Logger.error('ErrorBoundary', 'ErrorBoundary', error)}>

      <Router>

        <Switch>
          {routes}
        </Switch>

      </Router>

    </ErrorBoundary>
  );
}

export default App;
