import
  React
from 'react';

import { 
  withRouter 
} from 'react-router-dom';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Image,
  Images,
  DataStore,
} from 'common';

import {
  Button,
  H2,
  H3,
  P,
  PSmall,
  PSmaller,
  Text,
  Carousel,
} from 'common/components';

import {
  EventCommitProgressBar,
} from './EventCommitProgressBar';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  padding: 5px 15px 15px 15px;
  position: ${props => props.position || 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  overflow: auto;
`;

const ImageContainer = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  height: 160px;
  width: 100%;
`;

const Thumbnail = styled.div`
  border-radius: 5px;
  height: 50px;
  position: relative;
  overflow: hidden;
`;

const VentureTargetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 5px 5px 15px;
  box-shadow: 0px 0px 15px 15px ${Colors.Black};
`;

const BackIcon = styled.div`
  background-color: ${Colors.Black};
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 45px;
  padding: 0 0 0 15px;
  border-radius: 0 0 15px 0;
  box-shadow: 3px 3px 5px 0 ${Colors.Black};
`;

const EventDetailsComponent = props => {

  const [imageSrc, imageSrcSetState] = React.useState(null);

  const onThumbnailClick = e => {
    imageSrcSetState(e.target.src);
  };

  const onCommitClick = () => {
    
    DataStore.set(
      'commitVentureDetails',
      event,
    );

    props.history.push('/event/commit/personal');
  };

  let eventUploads = (props.event && props.event.eventMediaUploads) || [];
  let event = props.event || {};
  let galleryImages = [];
  let eventUpload;
  let currentImage;

  for (let i = 0; i < eventUploads.length; i++) {

    eventUpload = eventUploads[i];

    if (!eventUpload || !eventUpload.mediaUpload || !eventUpload.mediaUpload.url) {
      continue;
    }

    if (imageSrc) {
      currentImage = imageSrc;
    }

    if (!currentImage) {
      currentImage = eventUpload.mediaUpload.url;
    }

    galleryImages.push(
      <Thumbnail
        key={i}>

        <Image
          height={'100%'}
          src={eventUpload.mediaUpload.url}
          onClick={onThumbnailClick}
        />

      </Thumbnail>
    )
  }

  return (

    <Container>
      
      <ContentContainer
        position={props.hideFooter ? 'relative' : 'absolute'}>

        <H2
          margin={'10px auto 0.8em auto'}
          width={'80%'}
          textAlign={'center'}>

            {event.name}
        </H2>

        <ImageContainer
          src={currentImage}
        />

        <H3>Gallery</H3>

        <Carousel
          hideArrows={true}>

            {galleryImages}
        </Carousel>
        
        <H3>About the event</H3>

        <PSmall
          lineHeight={'1.5'}>

            {event.description || 'No description provided'}
        </PSmall>

      </ContentContainer>

      { !props.hideBackArrow &&

        <BackIcon>

          <Image
            height={'40px'}
            padding={'20px 0 0 0'}
            src={Images.Icons.ArrowLeft}
            onClick={props.onBackClick}
          />

        </BackIcon>
      }

      { !props.hideFooter &&

        <FooterContainer>

          <EventCommitProgressBar
            min={event.minTickets}
            max={event.maxTickets}
            commited={event.eventTotalPledgers}
          />
          
          <VentureTargetContainer>

            <Text
              fontFamily={FontFamilies.Bold}
              fontSize={FontSizes.Bigger}>
                {event.eventTotalPledgers}/{event.minTickets}
            </Text>

            <Text
              fontFamily={FontFamilies.Bold}
              fontSize={FontSizes.Small}>
                Venture Target
            </Text>

          </VentureTargetContainer>

          <Button
            borderRadius={'5px'}
            flexDirection={'column'}
            height={'100%'}
            width={'250px'}            
            onClick={onCommitClick}>

            <PSmaller
              padding={'0 0 4px 0'}
              fontFamily={FontFamilies.Bold}>
              
                Commit:
            </PSmaller>

            <P
              fontFamily={FontFamilies.Bold}>

                R{event.reservationPrice}
            </P>

          </Button>

        </FooterContainer>
      }

    </Container>
  );
};

export const EventDetails = withRouter(EventDetailsComponent);