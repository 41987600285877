import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  FontFamilies,
} from 'common';

import {
  Card,
} from 'common/components';

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  background: ${props => props.isSelected ? Colors.PrimaryOne : Colors.DarkGrey};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Text = styled.div`
  color: ${props => props.color || Colors.White};
  font-size: ${props => props.fontSize || FontSizes.Big};
  font-family: ${props => props.fontFamily || FontFamilies.Bold};
`;

export const EventCategoryCard = props => {

  return (

    <Card
      width={props.width || '200px'}
      height={props.height || '100px'}
      onClick={props.onClick}
      onClickArgs={props.onClickArgs}>

      { props.image &&

        <Image
          src={props.image}
        />
      }

      <TextContainer
        isSelected={props.isSelected}>

        <Text
          color={props.color}
          fontFamily={props.fontFamily}
          fontSize={props.fontSize}>

            {props.text}
        </Text>

      </TextContainer>

    </Card>
  );
};