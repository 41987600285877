import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Images,
} from 'common';

import {
  H4,
  PSmall,
  Text,
} from 'common/components/text';

import {
  Card,
} from './Card';

const Container = styled.div`
  position: relative;
  width: ${props => props.width || '220px'};
  padding-bottom: 56.25%;

  ${props => props.onClick && 'cursor: pointer;'}
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;
  padding: 0 5% 5% 5%;
  background: linear-gradient(0deg, ${Colors.Black} -35%, ${Colors.Transparent} 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const FavButton = styled.div`
  position: absolute;
  background-color: ${Colors.White};
  bottom: 0;
  margin-left: -15px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  left: 50%;
  background-image: url(${Images.Icons.Heart});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  cursor: pointer;
`;

const Callout = styled.div`
  border-radius: 5px;
  background-color: ${Colors.PrimaryOne};
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  color: ${Colors.White};
`;

const DateInfo = styled.div`
  border-radius: 5px;
  background-color: ${Colors.GreyBrown};
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  color: ${Colors.White};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Price = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 5% 5% 5%;
  color: ${Colors.White};
  font-size: ${FontSizes.Regular};
  font-family: ${FontFamilies.Bold};
`;

const PricePrefix = styled.span`
  font-size: ${FontSizes.Smallest};
  font-family: ${FontFamilies.Regular};
  padding: 0 5px 0 0;
`;

export const EventCard = props => {

  const onFavClick = () => {
    typeof props.onFavClick === 'function' && props.onFavClick(props.onFavClickArgs);
  };

  const onCardClick = () => {
    typeof props.onClick === 'function' && props.onClick();
  };

  return (
    
    <Container
      width={props.width}
      height={props.height}
      onClick={onCardClick}>

      <Card
        position={'absolute'}
        boxShadow={`0px 0px 30px 5px ${Colors.Black}`}
        width={'100%'}
        height={'100%'}>

        <Image
          src={props.image}
        />

        <TextContainer>

          <H4
            margin={'3px 0'}>

              {props.eventText}
          </H4>

          <PSmall
            margin={'3px 0'}>

              {props.location}
          </PSmall>

        </TextContainer>

      </Card>

      { !props.hideFavButton &&

        <FavButton
          onClick={onFavClick}
        />
      }

      { props.callout &&

        <Callout>{props.callout}</Callout>
      }

      { props.showDate &&
      
        <DateInfo>

          <Text fontSize={FontSizes.Smallest} lineHeight={1}>{props.month}</Text>
          <Text lineHeight={1} fontFamily={FontFamilies.Bold} fontSize={FontSizes.Big}>{props.day}</Text>
          <Text fontSize={FontSizes.Smallest} lineHeight={1}>{props.time}</Text>

        </DateInfo>
      }

      <Price>
        {props.pricePrefix && <PricePrefix>{props.pricePrefix}</PricePrefix>}
        {props.price}
      </Price>

    </Container>
  );
};