import
  React, {
  Component,
} from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontSizes,
  Images,
  Image,
  MediaQueries,
  Logger,
  isValidProviderResult,
} from 'common';

import {
  EventCard,
  H2,
  Text
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  HtmlModal
} from 'ui/components';

import {
  MainEventDetailsBlock
} from 'ui/home/components';

import {
  EventProvider
} from 'providers';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 68vh;

  @media only screen and ${MediaQueries.Md} {
    min-height: 80vh;
  }
`;

const MainImage = styled.div`
  background-image: url(${props => props.srcMobile});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media only screen and ${MediaQueries.Sm} {
    background-image: url(${props => props.srcDesktop});
  }
`;

const BottomFadeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background-color: ${Colors.Black};
  background: linear-gradient(0deg, ${Colors.Black} 0%, ${Colors.Transparent} 100%);
  pointer-events: none;
`;

const Container = styled.div`
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 4%;
`;

const MainEventDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 4%;
`;

const EventsContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr;
  background-color: ${Colors.Black};
  background: linear-gradient(0deg, ${Colors.Black} 50%, ${Colors.Transparent} 100%);
  padding: 0 0 4% 0;

  @media only screen and ${MediaQueries.Md} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3vmin;
  }

  @media only screen and ${MediaQueries.Lg} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3vmin;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
    adding-top: 20px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    align-items: ${props => props.desktopAlignItems || 'flex-end'};
  }
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    width: 380px;
  }
`;

const FooterMobileSpace = styled.div`
  height: 15px;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const FooterTermsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
`;

const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SponsorImage = styled.img`
  display: none;

  @media only screen and ${MediaQueries.Md} {
    display: inline;
  }
`;

const SponsorImageMobile = styled.img`
  display: inline;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

class homeUI extends Component {

  state = {
    coverImageDesktop: '',
    coverImageMobile: '',
    sponsorBannerDesktop: {},
    sponsorBannerMobile: {},
    footerLogos: [],
    mainEvent: {},
    events: [],
    merchCards: [],
    showTerms: false,
    showPrivacyPolicy: false,
  };

  onGetTicketsClick = (event) => {

    DataStore.set('homeScrollPosition', document.getElementById('main-content').scrollTop);
    this.props.common.navigate(`/event/details/${event.id}`);
  };

  onGetMerchClick = (eId) => {

    DataStore.set('homeScrollPosition', document.getElementById('main-content').scrollTop);
    this.props.common.navigate(`/event/merch/select?e_id=${eId}`);
  };

  load = async () => {

    let siteConfigRet = await EventProvider.getEventSiteConfig();

    if (siteConfigRet.ok && siteConfigRet.data) {

      let host = window.location.host;
      let subdomain = host.split('.')[0];

      const siteConfig = siteConfigRet.data[subdomain] || siteConfigRet.data.default;

      if (siteConfig) {

        const merchCards = (siteConfig.merch_cards || []).map((m, index) => (
          <EventCard
            key={`m-card-${index}`}
            image={m.cover_media_url}
            eventText={m.title}
            location={m.sub_title}
            height={'250px'}
            width={'100%'}
            hideFavButton={true}
            price={m.price_text}
            pricePrefix={m.price_pre_text}
            showDate={false}
            onClick={() => this.onGetMerchClick(m.event_id)}
          />
        ));

        const footerLogoComponents = (siteConfig.footer_logos || []).map((logo, index) => (
          <Image
            key={`footerlogo-${index}`}
            src={logo.src}
            height={logo.height}
            width={logo.width}
            margin={'20px 0 20px 30px'}
            onClick={() => window.open(logo.redirect_url, '_blank')}
          />
        ));

        this.setState({
          coverImageDesktop: siteConfig.splash_desktop,
          coverImageMobile: siteConfig.splash_mobile || siteConfig.splash_desktop,
          sponsorBannerDesktop: siteConfig.sponsor_banner_desktop,
          sponsorBannerMobile: siteConfig.sponsor_banner_mobile || siteConfig.sponsor_banner_desktop,
          footerLogos: footerLogoComponents,
          merchCards: merchCards
        });
      }
    }

    let ret = await EventProvider.getPublicLiveEvents();

    this.props.common.hideLoading();

    if (!isValidProviderResult(ret)) {

      // TODO: log error
      // Show toast
      return;
    }

    if (!Array.isArray(ret.data) || !ret.data.length) {

      // TODO: handle no events found to display
      return;
    }

    let eventComponents = [];

    for (const event of ret.data) {

      if (!this.state.mainEvent.id) {

        this.setState({
          mainEvent: event
        });

        continue;
      }

      const isSoldOut = event.total_available_tickets <= 0;

      eventComponents.push(
        <EventCard
          key={event.id}
          image={event.cover_media_url}
          eventText={event.name}
          location={event.location_display}
          height={'250px'}
          width={'100%'}
          hideFavButton={true}
          month={event.displayMonth}
          day={event.displayDay}
          price={event.priceDisplay}
          pricePrefix={event.pricePrefix}
          time={event.displayTime}
          callout={isSoldOut
            ? 'Sold Out'
            : event.total_available_tickets < 20
              ? `Only ${event.total_available_tickets} left`
              : ''
          }
          showDate={!event.is_multi_event}
          onClick={() => !isSoldOut && this.onGetTicketsClick(event)}
        />
      );
    }

    this.setState({
      events: eventComponents
    });

    setTimeout(() => {
      const scrollPosition = DataStore.get('homeScrollPosition');
      if (scrollPosition) {
        document.getElementById('main-content').scrollTo(0, parseInt(scrollPosition, 10));
        DataStore.clear('homeScrollPosition')
      }
    }, 0);
  };

  componentDidMount = () => {

    homeUI.timestamp = new Date().getTime();

    DataStore.clear('verifyGuid');
    DataStore.clear('selectedTickets');
    DataStore.clear('selectedEvent');

    this.load()
      .catch(e => {
        
        Logger.error('Home', 'load', e);

        this.props.common.showToast('Oh no, we weren\'t able to load event details, please try again.');

        this.props.common.hideLoading();
      });
  };

  render () {

    return (

      <>

        <MainContainer>

          <MainImage
            srcDesktop={this.state.coverImageDesktop || this.state.mainEvent.featured_media_url || this.state.mainEvent.cover_media_url}
            srcMobile={this.state.coverImageMobile || this.state.mainEvent.featured_media_url || this.state.mainEvent.cover_media_url}
          >

            { !this.state.coverImageDesktop &&
              
              <MainEventDetailsContainer>

                <MainEventDetailsBlock
                  isSoldOut={this.state.mainEvent.total_available_tickets <= 0}
                  name={this.state.mainEvent.name}
                  location={this.state.mainEvent.location_display}
                  date={this.state.mainEvent.displayDate}
                  price={this.state.mainEvent.priceDisplay}
                  pricePrefix={this.state.mainEvent.pricePrefix}
                  onGetTicketClick={() => this.onGetTicketsClick(this.state.mainEvent)}
                />
              

              </MainEventDetailsContainer>
            }

          </MainImage>

          <BottomFadeContainer/>

        </MainContainer>

        <Container>

          <H2>Upcoming Events</H2>
          
            { true &&
            
              <EventsContainer>

                {this.state.events}

              </EventsContainer>
            }

        </Container>

        { this.state.merchCards.length > 0 &&
        
          <Container>

            <H2 margin={'0 0 0.8em 0'}>Merchandise</H2>
            
              <EventsContainer>

                {this.state.merchCards}

              </EventsContainer>

          </Container>
        }

        <Container>
          <FooterMobileSpace/>
          { this.state.sponsorBannerDesktop?.src &&
            <SponsorImage
              alt={'sponsors'}
              style={{
                width: this.state.sponsorBannerDesktop.width || '100%',
                height: this.state.sponsorBannerDesktop.height || 'auto'
              }}
              src={this.state.sponsorBannerDesktop.src + '?t=' + + homeUI.timestamp} />
            }
          { this.state.sponsorBannerMobile?.src &&
            <SponsorImageMobile
              alt={'sponsort'}
              style={{
                width: this.state.sponsorBannerMobile.width || '100%',
                height: this.state.sponsorBannerMobile.height || 'auto'
              }}
              src={this.state.sponsorBannerMobile.src + '?t=' + + homeUI.timestamp}
            />
          }
        </Container>

        <Container>

          <Footer>

            <FooterColumn
              desktopAlignItems={'flex-start'}>

              <FooterRow>
                <Text fontSize={FontSizes.Small}>For support</Text>
                <Text fontSize={FontSizes.Small}>support@weticket.co.za</Text>
              </FooterRow>
              <FooterRow>
                <Text fontSize={FontSizes.Small}>Sell on WeTicket</Text>
                <Text fontSize={FontSizes.Small}>sales@weticket.co.za</Text>
              </FooterRow>

            </FooterColumn>

            <FooterColumn>

              <FooterLogoContainer>
                <Image
                  src={Images.Logo}
                  width={'170px'}
                />

                {this.state.footerLogos}
              </FooterLogoContainer>

            </FooterColumn>

          </Footer>

            <FooterTermsContainer>
              <FooterRow>
                <Text fontSize={FontSizes.Small} onClick={() => this.setState({ showTerms: true })}>Terms of Use</Text>
                <Text fontSize={FontSizes.Small} onClick={() => this.setState({ showPrivacyPolicy: true })}>Privacy Policy</Text>
              </FooterRow>
            </FooterTermsContainer>

        </Container>

        <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_terms.html?v=' + homeUI.timestamp} show={this.state.showTerms} onClose={() => this.setState({ showTerms: false })}/>
        <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_privacy_policy.html?v=' + homeUI.timestamp} show={this.state.showPrivacyPolicy} onClose={() => this.setState({ showPrivacyPolicy: false })}/>
      </>
    );
  }
}

export const Home = withCommon(homeUI, {
  applyMaxWidth: false,
  contentBackgroundColor: Colors.Black,
});