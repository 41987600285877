import
  React
from 'react';

import {
  Colors,
  FontSizes,
} from 'common';

import
  styled
from 'styled-components';

const Container = styled.div`
  border: 1px solid ${Colors.PrimaryOne};
  border-radius: 10px;
  color: ${Colors.PrimaryOne};
  display: flex;
  font-size: ${FontSizes.Small};
  justify-content: center;
  text-align: center;
  padding: ${props => props.padding || '25px'};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.width && `width: ${props.width};`}
`;

export const UserMessage = props => {

  return (

    <Container
      margin={props.margin}
      padding={props.padding}
      width={props.width}>

        {props.text || props.children}
    </Container>
  );
};