import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  Images,
  Image,
} from 'common';

const Container = styled.div`
  padding: ${props => props.padding || '0'};
  width: 100%;
`;

const Header = styled.div`
  background-color: ${Colors.LightGreyBrown};
  border-radius: 5px;
  color: ${Colors.White};
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: ${FontSizes.Small};
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: 0.4s;
`;

const Panel = styled.div`
  position: relative;
  top: -5px;
  border-radius: 0 0 5px 5px;
  padding: 0;
  background-color: ${Colors.GreyBrown};
  overflow: hidden;
  max-height: ${props => props.maxHeight}px;

  ${props => !props.removeAnimation && `transition: max-height 0.2s ease-out;`}
`;

const PanelContent = styled.div`
  padding: 20px 15px 15px 15px;
`;

const ArrowImage = styled(Image)`
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease-out;
`;

export const Accordion = props => {

  const [state, setState] = React.useState({
    open: !!props.fixedOpen || !!props.open,
    panelMaxHeight: 0,
  });

  const panel = React.createRef();

  const onHeaderClick = (e) => {

    if (props.fixedOpen) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      open: !prevState.open,
    }));
  };

  React.useEffect(() => {

    const maxHeight = state.open
      ? panel.current.scrollHeight
      : 0;

    setState(prevState => ({
      ...prevState,
      panelMaxHeight: maxHeight,
    }));

  // eslint-disable-next-line
  }, [state.open]);

  return (

    <Container
      id={props.id}
      padding={props.padding}>

      <Header
        onClick={onHeaderClick}>

          <div>
            {props.header || 'Click to open'}
          </div>

          { !props.fixedOpen &&

            <ArrowImage
              height={'15px'}
              src={Images.Icons.Arrow}
              isOpen={state.open}
            />
          }

      </Header>

      <Panel
        removeAnimation={props.fixedOpen}
        isOpen={state.open}
        maxHeight={state.panelMaxHeight}
        ref={panel}>

          <PanelContent>
            {props.children}
          </PanelContent>
          
      </Panel>

    </Container>
  );
};