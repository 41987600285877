import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  MediaQueries
} from 'common';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0 0;

  @media only screen and ${MediaQueries.Sm} {
    justify-content: center;
  }
`;

const Input = styled.input`
  background-color: ${Colors.GreyBrown};
  border: none;
  color: ${Colors.White};
  font-size: ${FontSizes.Regular};
  font-family: ${FontFamilies.Bold};
  height: 18vw;
  width: 18vw;
  border-radius: 10px;
  outline: none;
  text-align: center;

  @media only screen and ${MediaQueries.Sm} {
    height: 55px;
    width: 55px;
  }
`;

const Space = styled.div`
  width: 20px;
`;

export const OtpInput = forwardRef((props, ref) => {

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const handleInputChange = (index, e) => {

    let value = e.target.value;

    if (value.length > 1) {

      e.target.value = value.charAt(value.length - 1);
      value = e.target.value;
    }

    if (value.length === 1 && index < 3) {

      if (index === 0) {
        inputRef2.current.focus();
      }

      if (index === 1) {
        inputRef3.current.focus();
      }

      if (index === 2) {
        inputRef4.current.focus();
      }
    }

    if (value.length === 0) {

      if (index === 1) {
        inputRef1.current.focus();
      }

      if (index === 2) {
        inputRef2.current.focus();
      }

      if (index === 3) {
        inputRef3.current.focus();
      }
    }

    const otp = inputRef1.current.value + inputRef2.current.value + inputRef3.current.value + inputRef4.current.value;

    props.onChange && props.onChange(otp);
  };

  const handleFocus = (e) => {
    const value = e.target.value;
    setTimeout(() => e.target.setSelectionRange(value.length, value.length), 50);
  };

  const handleKeyDown = (index, e) => {

    if (e.key === 'Backspace' && e.target.value === '') {
      if (index === 1) setTimeout(() => inputRef1.current.focus());
      if (index === 2) setTimeout(() => inputRef2.current.focus());
      if (index === 3) setTimeout(() => inputRef3.current.focus());
    }
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
      inputRef1.current.focus();
    },
  }));

  React.useEffect(() => {

    inputRef1.current.focus();
  }, []);

  return (
    <Container>
      <Input
        ref={inputRef1}
        type="tel"
        autoComplete={"off"}
        onChange={(e) => handleInputChange(0, e)}
        onKeyDown={(e) => handleKeyDown(0, e)}
        onClick={handleFocus}
      />
      <Space/>
      <Input
        ref={inputRef2}
        type="tel"
        autoComplete={"off"}
        onChange={(e) => handleInputChange(1, e)}
        onKeyDown={(e) => handleKeyDown(1, e)}
        onClick={handleFocus}
      />
      <Space/>
      <Input
        ref={inputRef3}
        type="tel"
        autoComplete={"off"}
        onChange={(e) => handleInputChange(2, e)}
        onKeyDown={(e) => handleKeyDown(2, e)}
        onClick={handleFocus}
      />
      <Space/>
      <Input
        ref={inputRef4}
        type="tel"
        autoComplete={"off"}
        onChange={(e) => handleInputChange(3, e)}
        onKeyDown={(e) => handleKeyDown(3, e)}
        onClick={handleFocus}
      />
    </Container>
  );
});